import React, { useCallback, useEffect, useState } from "react";
import { convertToOptionsList, formatCNPJ } from "../../utils";
import {
  IDistributors,
  useDistributor,
} from "../../contexts/distributor/DistributorContext";
import MultipleSelectCheckbox from "../MultipleSelectCheckbox";

interface SelectDistributorsProps {
  label?: string;
  maxWidth?: number;
  onChangeValue?: (e?: IDistributors) => void;
  onChange?: (selectedOption: string) => void;
}

const MultipleSelectDistributors: React.FC<SelectDistributorsProps> = ({
  label = "",
  maxWidth,
}) => {
  const {
    distributors,
    selectedDistributorsStoraged,
    setSelectedDistributorsStoraged,
  } = useDistributor();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codeDistributors, setCodeDistributors] = useState<string[]>([]);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const setDistributors = useCallback(() => {
    const storedValue = localStorage.getItem("multipleSelectedDistributor");
    if (firstLoading) {
      if (storedValue) {
        setCodeDistributors(JSON.parse(storedValue));
      } else {
        const allDistributors: any[] = distributors.map((ele) => ele.id);
        setCodeDistributors(allDistributors);
        setFirstLoading(false);
      }
    }
  }, [setCodeDistributors, setFirstLoading, firstLoading, distributors]);

  useEffect(() => {
    setSelectedDistributorsStoraged(codeDistributors);
  }, [codeDistributors, setSelectedDistributorsStoraged]);

  useEffect(() => {
    if (distributors.length === 0) return;
    setDistributors();
  }, [distributors]);

  const handleChangeSelectedDistributors = useCallback(
    (event: string[]) => {
      if (event?.length > 0) {
        setCodeDistributors(event);
        localStorage.setItem(
          "multipleSelectedDistributor",
          JSON.stringify(event)
        );
      } else {
        if (!firstLoading) {
          if (
            event.length === 0 &&
            codeDistributors.length !== 0 &&
            selectedDistributorsStoraged.length !== 0
          ) {
            setCodeDistributors(event);
            localStorage.setItem(
              "multipleSelectedDistributor",
              JSON.stringify([])
            );
          }
        }
      }
    },
    [
      setCodeDistributors,
      firstLoading,
      codeDistributors,
      selectedDistributorsStoraged,
    ]
  );

  const distributorsCustom = distributors.map((ele) => ({
    ...ele,
    description: `${ele.description} - ${formatCNPJ(ele.document) ?? ""}`,
  }));

  return (
    <>
      <MultipleSelectCheckbox
        label={label}
        maxWidth={maxWidth ?? 350}
        options={convertToOptionsList(distributorsCustom, "id", "description")}
        value={codeDistributors}
        onChange={handleChangeSelectedDistributors}
      />
    </>
  );
};

export default MultipleSelectDistributors;
